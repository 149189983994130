<template>
  <div>
    <b-row align-h="center">
      <b-col lg="8">
        <div class="font-weight-bold mb-1">
          {{ filterResult.length }} results found <span v-if="filterResult.length > perPage">| Showing results {{ `from ${showing[0]} to ${showing[1]}` }}</span>
        </div>
        <b-input-group class="my-2 input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            placeholder="Search (name)"
            :disabled="loading || items.length < 1"
          />
        </b-input-group>
        <b-card no-body>
          <error-display
            v-if="error"
            @action-clicked="loadData"
          />
          <loading-container
            v-else-if="loading"
          />
          <template v-else>
            <b-table
              responsive
              hover
              :items="filterResult"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              @row-clicked="rowClicked"
            />
            <div
              class="py-2 d-flex justify-content-between"
            >
              <div class="my-2">
                <span class="pb-1">Rows Per Page</span>
                <b-form-select
                  v-model="perPage"
                  :options="perPageOption"
                  class="pagination-select"
                />
              </div>

              <div class="my-2 d-flex flex-column justify-content-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filterResult.length"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  class="my-0"
                />
              </div>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="showLimitModal"
      ok-title="Proceed with Limited Product Set"
      cancel-variant="danger"
      cancel-title="Cancel"
      modal-class="modal-danger"
      centered
      title="Product Per Portfolio Limit Reached"
      @ok="openVirtualReport(selectedRowId, true)"
      @hide="showLimitModal = false; selectedRowId = null;"
    >
      <b-card-text>
        You can analyse a maximum of {{ maxNumberOfProductsInPortfolio }} products in one portfolio.
        The analysis will include only a subset of products.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BFormSelect, BPagination, BInputGroup, BInputGroupPrepend, BFormInput, BModal,
} from 'bootstrap-vue'
import ErrorDisplay from '@/views/components/ErrorDisplay.vue'
import LoadingContainer from '@/views/components/LoadingContainer.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    ErrorDisplay,
    LoadingContainer,
  },
  data() {
    return {
      fields: [
        {
          key: 'issuername', label: 'Issuer', thStyle: { width: '75%' }, sortable: true,
        },
        {
          key: 'count', label: 'Number of products', thStyle: { width: '25%' }, sortable: true,
        },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
      perPageOption: [5, 10, 15, 20, 50, 100],
      filter: '',
      showLimitModal: false,
      selectedRowId: null,
      error: null,
      loading: false,
    }
  },
  computed: {
    maxNumberOfProductsInPortfolio() {
      return localStorage.getItem('maxNumberOfProductsInPortfolio') || this.$maxPortfolioSize
    },
    filterResult() {
      if (this.filter && this.filter.length > 0) {
        return this.items.filter(item => (item.issuername.toLowerCase().search(this.filter.toLowerCase()) !== -1))
      }
      return this.items
    },
    showing() {
      const start = (this.perPage * this.currentPage) - this.perPage + 1
      const end = (this.perPage * this.currentPage) > this.filterResult.length ? this.filterResult.length : (this.perPage * this.currentPage)
      return [start, end]
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.error = null
      this.$http
        .get('data.cfc?method=getIssuers')
        .then(response => { this.items = response.data.content })
        .catch(() => {
          this.error = true
        })
        .then(() => {
          this.loading = false
        })
    },
    rowClicked(row) {
      if (row.count > this.maxNumberOfProductsInPortfolio) {
        this.selectedRowId = row.issuerid
        this.showLimitModal = true
        return
      }
      this.openVirtualReport(row.issuerid)
    },
    openVirtualReport(issuerId, reduce = false) {
      this.$router.push({
        name: 'portfolio-report',
        params: {
          portfolioId: 'v',
          searchFilter: {
            search: '',
            filter: { issuer: [issuerId] },
            reduceToLimit: reduce,
            sortBy: '',
            sortDesc: false,
          },
        },
        query: {
          src: this.$route.name,
        },
      })
    },
  },
}
</script>

<style>
</style>
